// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
.custom-applicant-name, .custom-applicant-name:hover{
    color:#eee !important;
    cursor:default !important;
}

.action-btn {
    cursor: pointer;
}

.pac-container {
    z-index: 1054 !important;
 }

 
.language-select {
     background-color: aqua;
     color: black;
     
 }
// .language-select option:hover {background-color: #ffc832}

 header-text {
     color: white;
     text-align: center;
 }

.btn-auth {
    background: #FACD48 !important;
    border-color: #FACD48 !important;
    color:black !important;
}

.front-pages-bg {
    background-color: $white !important;
}

.reg-box {
    background-color:#105970;
    max-width: 400px;
}

$third_color: #0a8083 !default;

.hr_separator {
    background-color: $third_color; 
    height: 10px;
}

.background-secondary {
    background-color: #b2e0e1;
    color: black;
}
.background-secondary-whitetext {
    background-color: #b2e0e1;
    color: white;
}
.background-third-whitetext {
    background-color: #0a8083;
    color: white;
}
.background-danger-third-whitetext {
    background-color: #d5445c !important;
    color: white;
  }

.btn-save {
    background-color: #0a8083;
    color: white;
}

.btn-success1 {
    background-color:#ffc832;
    border-color: #ffc832; 
    outline: #ffc832;
    color: $black;
}

.btn-success1:hover {
    background-color:#c29a2d;
    border-color:#c29a2d;
    outline:#c29a2d;
    color: $black;
}

.btn-success1:disabled {
    background-color:#eed389;
    border-color:#eed389;
    outline:#eed389;
    color: #fff;
}

.btn-success1:active {
    background-color:#c29a2d;
    border-color:#c29a2d;
    outline:#c29a2d;
    color: $black;
}

.btn-success1:focus {
    background-color:#c29a2d;
    border-color:#c29a2d;
    outline:#c29a2d;
    color: $black;
}

.float-btn {
    position:fixed;
	width:50px;
	height:50px;
	top:107px;
	right:40px;
	background-color:#ffc832;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    box-shadow: 2px 2px 3px #999;
    font-size: 22px;
    border: none;
}

.float2-btn {
    position:fixed;
	width:50px;
	height:50px;
	top:175px;
	right:40px;
    background-color: gray;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    box-shadow: 2px 2px 3px #999;
    font-size: 22px;
    border: none;
}

.add-details-btn {
	border-radius: 40px;
	font-size: 16px;
	border: none;
	background-color: #ffc832;
	color: black;
	padding: 7px 20px;
  }

  .navbar-header {
      width:0px;
  }

  .text-success1 {
      color: #0a8083;
  }

  .text-success2 {
      color: #00b5bf;
  }

  .label-success1 {
    background-color: #0a8083;
    color: white;
}

  .text-dfa {
    color: black;
}

.text-danger2 {
    background-color: #ffc832;
    
}

.big-btn {
    width: 25%;
    border-radius: 40px;
    font-size: 22px;
    border: none;
    background-color: #0a8083;
    position: absolute;
    top: 12px;
}
.md-btn {
    border-radius: 40px;
    font-size: 15px;
    border: none;
    background-color: #0a8083;
    position: absolute;
    top: 12px;
}
// .big-btn:hover {
//     background-color:#c29a2d;
//     border-color:#c29a2d;
//     outline:#c29a2d;
//     color: $black;
// }

// .big-btn:active {
//     background-color:#c29a2d;
//     border-color:#c29a2d;
//     outline:#c29a2d;
//     color: $black;
// }

// .big-btn:focus {
//     background-color:#c29a2d;
//     border-color:#c29a2d;
//     outline:#c29a2d;
//     color: $black;
// }

.toast-success {
    background-color: #0a8083 !important;
  }
  .toast-error {
    background-color: #d5445c !important;
  }
  .toast-info {
    background-color: #105970 !important;
  }
  .toast-warning {
    background-color: #ffc832 !important;
  }

  .capta {
    background-image: url('/assets/images/capta-background.jpeg');
    color: #ecdb0a;
    text-align: center;
    cursor: default;
  }