/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

//(Required file)
@import "./variable.scss";

//Theme colors
@import "./theme-colors/dark-theme.scss";

// Import Bootstrap source files (Required file)
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

//This is for the components (Required file)
@import "./components.scss";

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import "./pages.scss";

//This is for the widgets (Required file)
@import "./widgets/widgets.scss";

//This is for the horizontal version if you are not using horizontal version you can remove this
@import "./horizontal/horizontal.scss";

//This is for the responsive (Required file)
@import "./responsive.scss";

//In This scss you can write your scss
@import "./custom.scss";

//This is for the icons (Required file)
@import "icons/font-awesome/css/fontawesome-all.css";
@import "icons/simple-line-icons/css/simple-line-icons.css";
@import "icons/weather-icons/css/weather-icons.min.css";
@import "icons/themify-icons/themify-icons.css";
@import "icons/flag-icon-css/flag-icon.min.css";
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "icons/crypto-icons/cryptocoins.css";

// this is for the perfect scrollbar
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
