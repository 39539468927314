// Theme colors
#main-wrapper {

  &[data-layout="vertical"],
  &[data-layout="horizontal"] {

    .topbar .navbar-collapse,
    .topbar {
      &[data-navbarbg="skin1"] {
        background: $skin1;
      }

      &[data-navbarbg="skin2"] {
        background: $skin2;
      }

      &[data-navbarbg="skin3"] {
        background: $skin3;
      }

      &[data-navbarbg="skin4"] {
        background: $skin4;
      }

      &[data-navbarbg="skin5"] {
        background: $skin5;
      }

      &[data-navbarbg="skin6"] {
        background: $skin6;

        .navbar-brand {
          .dark-logo {
            display: inline;
          }

          .light-logo {
            display: none;
          }
        }

        .nav-toggler,
        .topbartoggler {
          color: $body-color;
        }
      }
      &[data-navbarbg="skin7"] {
        background: $skin7;
      }
    }

    /*This is for the sidebar bg*/
    .left-sidebar {

      &[data-sidebarbg="skin1"],
      &[data-sidebarbg="skin1"] ul {
        background: $skin1;
      }

      &[data-sidebarbg="skin2"],
      &[data-sidebarbg="skin2"] ul {
        background: $skin2;
      }

      &[data-sidebarbg="skin3"],
      &[data-sidebarbg="skin3"] ul {
        background: $skin3;
      }

      &[data-sidebarbg="skin4"],
      &[data-sidebarbg="skin4"] ul {
        background: $skin4;
      }

      &[data-sidebarbg="skin5"],
      &[data-sidebarbg="skin5"] ul {
        background: $skin5;
      }

      &[data-sidebarbg="skin7"],
      &[data-sidebarbg="skin7"] ul {
        background: $skin7;
      }

      &[data-sidebarbg="skin6"] {
        background: $skin6;

        .user-profile {
          .profile-text {
            a {
              color: $body-color;
            }
          }
        }

        .sidebar-nav {
          ul {
            background: $skin6;

            .sidebar-item {
              .sidebar-link {
                color: $sidebar-text-dark;

                i {
                  color: $sidebar-icons-dark;
                }
              }
            }

            .nav-small-cap {
              color: $sidebar-text-dark;
              opacity: 0.7;
            }
          }

          .has-arrow {
            &::after {
              border-color: $sidebar-text-dark;
            }
          }

          .user-content,
          .user-name,
          .user-email {
            color: $theme-dark;
          }
        }
      }
    }
  }
}
