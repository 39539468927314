// Responsive
/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/


/*============================================================== 
 For Desktop & above all (1650px) 
 ============================================================== */


/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */


/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@include media-breakpoint-up(md) {
  .bc-content {
    justify-content: flex-end;
  }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@include media-breakpoint-down(md) {
  .do-block {
    display: block !important;
  }
}
